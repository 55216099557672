<template>
  <div id="summary" class="section explore">
    <!-- Circle shape background -->
    <div class="circle-bg"></div>
    <!-- Background image -->
    <div
      data-bgset=""
      class="bg-img lazyload row p-0 m-0 justify-content-center align-items-center"
    >
      <div class="caption">
          Photo credit: Bruno Destombes | MUTEK Montréal
        </div>
      <div
        data-aos="fade-in"
        class="col-md-6 mt-5 mt-md-0 col-12 explore-header text-center"
      >
        <div class="col-12">
          CFP Montréal<br />

          Executive Summary
        </div>
        <div class="col-12 mt-4 mb-5 mb-md-0">
          <div
            v-if="!showContent"
            @click="showContent = true"
            class="read-btn btn btn-outline-light"
          >
            Read
          </div>
          <MoreBtn v-else />
        </div>
      </div>

      <transition name="fade">
        <div
          v-if="showContent"
          class="col-md-6 col-12 row d-flex justify-content-center es-content pb-5 mb-5 mb-md-0"
        >
          <div class="col-12 pb-5 pb-md-0 mb-5 mb-md-0">
            <strong>About CFP:</strong> Creative Footprint (CFP) is a research
            project conducted by VibeLab and PennPraxis that researches creative
            spaces and communities in order to study the cultural strength and
            impact of a city’s music and nightlife. As of spring 2023, it has
            been conducted in Berlin, New York City, Tokyo, Stockholm, and
            Montréal. <br /><br />
            <strong>About CFP Montréal:</strong> This report, conducted in
            partnership with civic organisation MTL 24/24, details the findings
            of qualitative and quantitative research conducted in late 2022.
            Based on focus groups, interviews, and 14,600+ data points relating
            to Montréal’s approximately 271 music and nightlife venues, the
            report aims to provide a comprehensive picture of the city’s
            nightlife scene as it recovers from the impacts of Covid-19. This
            includes the spatial distribution of nightlife, relationships to
            other urban variables, and policy and social dynamics. <br />
            <br />
            <strong>What the report contains:</strong> Following overviews of
            Montréal’s night governance (Sec. I) and CFP methodology (Sec. II),
            the report details spatial and quantitative findings (Sec. III), as
            well as profiles of Montréal’s densest nightlife boroughs. A
            discussion of qualitative findings (Sec. IV) follows, including a
            deeper discussion of noise and sound closures, and spotlights on
            independent venues and collectives. The report closes with
            recommendations for action (Sec. V) with a Montréal-specific section
            on sustainability in nightlife, profiling one festival’s wraparound
            approach to sustainability and inclusion.
          </div>
        </div>
      </transition>
      <div class="rounded-bottom"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      images: ["1", "2", "3", "4", "5", "6"],
      showContent: false,
    };
  },
};
</script>
<style scoped>
.caption {
  position: absolute;
  top: 0;
  left: 0;
  padding: 2rem;
  color: #e8fcd4;
  opacity:0.3;
}
.es-content {
  z-index: 1;
  color: #e8fcd4;
}
.hero-img {
  border-radius: 2rem;
}

.read-btn {
  padding: 0.8rem 2rem 0.8rem 2rem;
  /* border: 1px solid white; */
  border-radius: 30px;
  z-index: 1;
}
.read-btn:hover {
  cursor: pointer;
}
.circle-bg {
  position: absolute;
  left: -30%;
  right: 0;
  top: 120vh;
  bottom: 0;
  /* background:#242321; */
  background: radial-gradient(#201f1d, #242321);
  /* background: pink; */
  height: 80vw;
  width: 80vw;
  border-radius: 50%;
  z-index: -1;
}
.explore-header {
  line-height: 2rem;
  font-size: 1.8rem;
  z-index: 1;
  color: #e8fcd4;
}
@media (min-width: 768px) {
  .explore-header {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
}

.img-1 {
  transform: translateY(100%);
}
.img-2 {
  transform: translateY(50%);
}
.img-4 {
  transform: translateY(100%);
}
.img-5 {
  transform: translateY(50%);
}

.explore {
  min-height: calc(100vh + 10rem);
  background: #201f1d;
  position: relative;
  height: auto;
}

.bg-img {
  background: url("~@/assets/img/dj.jpg") no-repeat bottom center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: cover;
  width: 100%;
  min-height: calc(100vh + 5rem);
  height:auto;
  position: relative;
}

@media (min-width: 768px) {
  .bg-img {
    background-attachment: fixed;
  }
}

.bg-img::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.577);
}
.rounded-bottom {
  border-radius: 50px;
  width: 100%;
  height: 5rem;
  bottom: 0;
  background: #201f1d;
  position: absolute;
}
h3 {
  text-align: center;
  font-family: SkModernistBold;
  font-size: 2rem;
}
.credit {
  font-size: 0.8rem;
  text-align: center;
  line-height: 1rem;
}
.name {
  font-family: SkModernistBold;
}
</style>