<template>
  <div id="themes" class="section themes">
    <div class="container-fluid row d-flex justify-content-center m-0">
      <div class="col-12 title text-center mb-5 pb-5 pt-5 mt-5">
        Key Recommendations:
      </div>

      <div
        class="col-md-8 col-12 mb-3"
        v-for="(theme, index) in themes"
        :key="index"
        @click="toggleAnswer(index)"
        data-aos="fade-in"
       
      >
        <div
          class="py-3 theme-wrapper px-3"
          :style="[
            clickedAnswer === index || index === 3
              ? ' background-color: #e8fcd4; color: #201f1d;'
              : '',
          ]"
        >
          <div class="col-12 position-relative">
            <h4 class="my-2 py-1 mx-1 me-4">{{ theme.title }}</h4>
            <span
              class="expand-cross d-flex justify-content-center align-items-center"
              ><svg
                :style="[
                  clickedAnswer === index
                    ? 'transform:rotate(45deg)!important'
                    : '',
                ]"
                width="15"
                height="15"
                viewBox="0 0 9 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 5.04H5.04V9H3.96V5.04H0V3.96H3.96V0H5.04V3.96H9V5.04Z"
                  fill="currentColor"
                ></path></svg
            ></span>
          </div>

          <transition
            name="expand"
            @enter="enter"
            @after-enter="afterEnter"
            @leave="leave"
          >
            <div v-if="clickedAnswer === index" class="col-12 answer mt-3" v-html="theme.body">
            
            </div>
          </transition>
        </div>
      </div>
   
     
    
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      clickedAnswer: null,
      themes: [
        {
          title: "1. Protect",
          body: "Protect and preserve Montréal’s existing venues and economic accessibility (particularly in districts with the majority of venues) through mapping and expanded sound protections for venues.",
        },
        {
          title: "2. Build trust",
          body: "Improved relationships and dialogue between nightlife, public safety, and municipal decision makers.",
        },
        {
          title: "3. Grow",
          body: "Expanding time and space for nightlife via later hours, transit access, and access to new, affordable spaces.",
        },
        {
          title: "These recommendations include, among others:",
          body: "<ul><li>Steps to strengthen venues’ resilience against, and ability to address, noise and sound conflicts;</li><li>Specific, local strategies to develop shared visions for the continued existence of their venues and nightlife;</li><li>Expanding nightlife creators’ access to information, including access to city decision makers and offices;</li><li>Updating alcohol licensing regulations to enable longer nights;</li><li>Incorporating urban planning and zoning mechanisms that promote the production of music events and new nightlife spaces, in Ville de Montréal’s forthcoming city planning and zoning documents and strategies</li><li>Drawing upon Montréal’s existing sustainability initiatives and expertise to establish the city as a global leader in sustainable, inclusive nightlife.</li>",
        },
      ],
    };
  },
  methods: {
    toggleAnswer(e) {
      if (this.clickedAnswer === e) {
        this.clickedAnswer = null;
      } else {
        this.clickedAnswer = e;
      }
    },
    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = "absolute";
      element.style.visibility = "hidden";
      element.style.height = "auto";

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    leave(element) {
      const height = getComputedStyle(element).height;

      element.style.height = height;

      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    },
  },
};
</script>
<style scoped>
.read-btn {
  padding: 0.8rem 2rem 0.8rem 2rem;
  /* border: 1px solid white; */
  border-radius: 30px;
  z-index: 1;
}
.read-btn:hover {
  cursor: pointer;
}
.expand-cross {
  position: absolute;
  right: 0.2rem;
  top: 0;
  bottom: 0;
  margin: auto;
  font-family: SkModernistBold;
  height: 1.8rem;
}
svg {
  transition: transform 650ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.theme-wrapper:hover svg {
  transform: rotate(180deg);
}
.theme-wrapper:hover {
  background-color: #e8fcd4;
  color: #201f1d;
}
.theme-wrapper {
  color: #e8fcd4;
  border: 1px solid #e8fcd4;
  border-radius: 40px;
  background-color: transparent;
  transition: all 0.15s ease-in-out;
}
.theme-wrapper:hover {
  cursor: pointer;
}
.expand-enter-active,
.expand-leave-active {
  transition: height 200ms ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}

.title {
  line-height: 2rem;
  font-size: 1.8rem;
  z-index: 1;
  color: #e8fcd4;
}
.themes {
  min-height: auto;
  background: #201f1d;
  position: relative;
}
@media (min-width: 768px) {
  .themes {
    min-height: 100vh;
  }
  .title {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
}

h3 {
  text-align: center;
  font-family: SkModernistBold;
  font-size: 2rem;
}
.answer {
  font-size:1.2rem;
}
</style>