<template>
  <div
    id="features"
    class="section features container-fluid d-flex justify-content-center align-items-center flex-column p-0"
  >
    <div class="col-12 d-flex justify-content-center">
      <div class="col-4 pt-5">
        <div class="divider"></div>
      </div>
    </div>
    <!-- Circle shape background -->
    <div class="circle-bg"></div>
    <!-- Content -->
    <div class="col-12 d-block d-md-none mobile-title mt-5 text-center">
      Preview the report
    </div>
    <div
      data-aos="fade-left"
      class="container-fluid bio-wrapper d-flex flex-row align-items-center pb-5 pt-5"
    >
      <div class="container row d-none d-md-block">
        <div class="features-title">Preview the report</div>
      </div>

      <div
        class="bio-card col-auto row justify-content-center d-flex"
        v-for="(artist, index) in featuresData"
        :key="index"
        :data-aos="[index % 2 == 0 ? 'fade-up' : 'fade-down']"
        @click="toggleFeature(index)"
      >
        <div class="col-md-9 col-10 position-relative p-0">
           <img
            :style="[index === clickedFeature ? 'opacity:0;' : '']"
            :height="artist.primary.image.dimensions.height"
            :width="artist.primary.image.dimensions.width"
            class="img-fluid img-wrapper lazyload"
            :data-src="artist.primary.image.url + '&q=3'"
            alt=""
          />
          <img
            :style="[index === clickedFeature ? 'opacity:1;' : '']"
            :height="artist.primary.image.dimensions.height"
            :width="artist.primary.image.dimensions.width"
            class="img-fluid spread lazyload"
            :data-src="artist.primary.spread.url  + '&q=6'"
            alt=""
          />
        </div>
        <div
          class="col-12 name d-flex justify-content-center align-items-center mt-4"
        >
          <MoreBtn v-if="index === clickedFeature" />
        </div>
      </div>
      <div class="bio-card col-auto row justify-content-center d-flex">
        <div class="col-md-9 col-10 pe-5 justify-content-center d-flex">
          <MoreBtn />
        </div>
      </div>
    </div>
    <div class="col-12 d-flex justify-content-center">
      <div class="col-4 pt-5">
        <div class="divider"></div>
      </div>
    </div>
    <div class="col-12 p-0 m-0">
      <div
        class="bg-img m-0 d-flex justify-content-center align-items-center p-0"
      >
       <div
          style="z-index: 1"
          data-aos="fade-in"
          class="col-12 col-md-9 py-5 mt-5"
        >
          <h3 class="mt-5">
"CFP captures the essence of Montréal's eclectic night culture and lays the foundations for a stronger community-driven nighttime ecosystem by identifying its strengths and weaknesses. It's a great tool to understand our vibrant local culture, as much for decision-makers and policymakers as for industry stakeholders and nighttime users."
        
        </h3>
          <div class="credit mt-4">
            <span class="name">—Mathieu Grondin,  
Executive director and cofounder, MTL 24/24</span>
          </div>
        </div>

        <div class="rounded-bottom"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      featuresData: [],
      clickedFeature: null,
    };
  },
  methods: {
    toggleFeature(e) {
      if (this.clickedFeature === e) {
        this.clickedFeature = null;
      } else {
        this.clickedFeature = e;
      }
    },
    getData() {
      this.$prismic.client.getSingle("cfp-m-preview").then((response) => {
        this.featuresData = response.data.body;
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>


<style lang="scss" scoped>
.circle-bg {
  position: absolute;
  right: -20%;

  top: 0;
  margin: auto;
  bottom: 0;
  /* background:#242321; */
  background: radial-gradient(#201f1d, #242321);
  /* background: pink; */
  // height: 120vh;
  width: 120vh;
  border-radius: 50%;
  z-index: -2;
}
.divider {
  width: 100%;
  height: 0.2rem;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    #e8fcd4 50%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0.6;
}
.mobile-title {
  color: #e8fcd4;
  line-height: 2rem;
  font-size: 1.8rem;
}

.bio-card:nth-child(even) {
  margin-top: 0;
}
.bio-card:nth-child(odd) {
  margin-top: 0;
}

.bio-card {
  width: 22rem;
  scroll-snap-align: center;
  scroll-margin: 0;
}
.bio-card:hover {
  cursor: pointer;
}

.bio-card-preview {
  width: 100%;
}

.credit {
  font-size: 0.8rem;
  text-align: center;
  line-height: 1rem;
}

h3 {
  color: #e8fcd4;
}
.name {
  font-family: SkModernistBold;
}

.bio-wrapper {
  top: 0;
  bottom: 0;
  min-height: auto;
  width: 100%;
  height: auto;
  overflow-x: scroll;
  overflow-y: hidden;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.bio-wrapper::-webkit-scrollbar {
  display: none;
}
.features {
  height: auto;
  min-height: auto;
  background: #201f1d;
  position: relative;
  overflow-x: hidden;
  overflow-y: visible;
}

@media (min-width: 768px) {
  .features {
    min-height: 130vh;
  }
  .bio-card:nth-child(even) {
    margin-top: 60px;
  }
  .bio-card:nth-child(odd) {
    margin-top: 0;
  }
  .bio-card {
    width: 28rem;
  }
  .bio-wrapper {
    min-height: 100vh;
  }
}

.img-wrapper {
  border-radius: 30px;
  transition: opacity 200ms ease;
}

.spread {
  box-shadow: 0px 0px 21px 4px rgba(0, 0, 0, 0.28);
  transition: opacity 200ms ease;
  border-radius: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
}
.name {
  text-align: center;
  font-family: SkModernistBold;
  font-size: 1.5rem;
  height: 3.3rem;
  color: #e8fcd4;
}
.features-title {
  color: #e8fcd4;
  text-align: center;
  width: 50vw;
  font-size: 2.8rem;
  line-height: 2.8rem;
  font-family: SkModernistRegular;
  scroll-snap-align: center;
  scroll-margin: 0;

  position: relative;
}
.extended-wrapper {
  overflow: scroll;
}

.bio-container {
  overflow: scroll;
  width: auto;
}

#biographies-slide {
  min-height: auto;
  height: auto;
  background: #003456;
}
.bg-img {
  background: url("~@/assets/img/non-stop.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: cover;
  background-position: center;
  width: 100%;
  height: calc(100vh + 5rem);
  position: relative;
  padding: 0;
}
@media (min-width: 768px) {
  .bg-img {
    background-attachment: fixed;
  }
}

.bg-img::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.4);
}

.rounded-bottom {
  border-radius: 50px;
  transform: rotate(180deg);
  width: 100%;
  height: 5rem;
  top: 0;
  background: #201f1d;
  position: absolute;
}
</style>




